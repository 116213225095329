import { DataTable } from '@/common/ui/data-table'
import { HoldingT } from '@/common/types'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import Money from '@/common/models/money'
import { Button } from '@/common/ui'
import useTradeModalStore from '@/trading/tradeModalStore'
import { PriceDiff } from '@/common/ui/PriceDiff.tsx'
import { SecurityHead } from '@/equities/securityHead.tsx'

type holdingsProps = {
    holdings: HoldingT[]
}

export function Holdings({ holdings }: holdingsProps) {
    const { setSecurityCodes, setOrderAction } = useTradeModalStore()

    const columns: ColumnDef<HoldingT>[] = useMemo(
        () => [
            {
                accessorKey: 'securityCode',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Company</span>
                },
                cell: ({ row }) => {
                    const instrumentCode = row.original.securityCode
                    return (
                        <SecurityHead
                            nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                            security={{
                                securityCode: instrumentCode,
                                securityName: row.original.name,
                            }}
                        />
                    )
                },
            },
            {
                accessorKey: 'dailyChange',
                enableSorting: true,
                sortingFn: (rowA, rowB) =>
                    (rowA.original.dailyChangeValue ?? 0) - (rowB.original.dailyChangeValue ?? 0),
                header: () => {
                    return <span className="text-primary font-bold">Today's Change</span>
                },
                cell: ({ row }) => {
                    const dailyChangeValue = Money.of(row.original.dailyChangeValue)
                    return (
                        <div className="self-stretch justify-start items-start gap-0.5 flex whitespace-nowrap">
                            <PriceDiff
                                label={
                                    Number.isFinite(row.original.dailyChangePercent)
                                        ? `${dailyChangeValue} (${Math.abs(row.original.dailyChangePercent!).toFixed(2)}%)`
                                        : `${dailyChangeValue}`
                                }
                                value={dailyChangeValue.value}
                            />
                        </div>
                    )
                },
            },
            {
                accessorKey: 'lastPrice',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Last Price</span>
                },
                cell: ({ row }) => {
                    const lastPrice = Money.of(row.original.lastPrice)
                    return <>{`${lastPrice}`}</>
                },
            },
            {
                accessorKey: 'units',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Shares</span>
                },
            },
            {
                accessorKey: 'currentValue',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Value</span>
                },
                cell: ({ row }) => {
                    const lastPrice = Money.of(row.original.lastPrice)
                    const sharesHeld = row.original.units
                    return <>{`${lastPrice.multiply(sharesHeld)}`}</>
                },
            },
            {
                accessorKey: 'totalChange',
                enableSorting: true,
                sortingFn: (rowA, rowB) =>
                    (rowA.original.totalChangeValue ?? 0) - (rowB.original.totalChangeValue ?? 0),
                header: () => {
                    return <span className="text-primary font-bold">Total Change</span>
                },
                cell: ({ row }) => {
                    const totalChange = Money.of(row.original.totalChangeValue)
                    return (
                        <div className="self-stretch justify-start items-start gap-0.5 flex whitespace-nowrap">
                            <PriceDiff
                                label={
                                    Number.isFinite(row.original.totalChangePercent)
                                        ? `${totalChange} (${Math.abs(row.original.totalChangePercent!).toFixed(2)}%)`
                                        : `${totalChange}`
                                }
                                value={totalChange.value}
                            />
                        </div>
                    )
                },
            },
            {
                accessorKey: 'trade',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Trade</span>
                },
                cell: ({ row }) => {
                    return (
                        <div className="flex gap-2">
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                    setSecurityCodes([row.original.securityCode])
                                    setOrderAction('BUY')
                                }}
                            >
                                Buy More
                            </Button>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                    setSecurityCodes([row.original.securityCode])
                                    setOrderAction('SELL')
                                }}
                            >
                                Sell
                            </Button>
                        </div>
                    )
                },
            },
        ],
        [setSecurityCodes, setOrderAction],
    )

    return <DataTable columns={columns} data={holdings.length ? holdings : []} noDataMessage="No shares held yet..." />
}
