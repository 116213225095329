import { DataTable } from '@/common/ui/data-table'
import { orderStatus, OrderT } from '@/common/types'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { Button } from '@/common/ui'
import useOrderReviewStore from './orderReviewStore'
import { ReviewOrderModal } from './reviewOrderModal'
import { CancelOrderSuccessModal } from './cancelOrderConfirmationModal'
import { SecurityHead } from '@/equities/securityHead.tsx'

type orderProps = {
    orders: OrderT[]
}

export function Orders(props: orderProps) {
    const { orders } = props
    const { setCurrentOrder } = useOrderReviewStore()
    const [reviewOrderModalOpen, setReviewOrderModalOpen] = useState<boolean>(false)
    const [cancelOrderSuccessModalOpen, setCancelOrderSuccessModalOpen] = useState<boolean>(false)

    const columns: ColumnDef<OrderT>[] = useMemo(
        () => [
            {
                accessorKey: 'securityCode',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Company</span>
                },
                cell: ({ row }) => {
                    const securityCode = row.original.securityCode
                    return (
                        <SecurityHead
                            nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                            security={{
                                securityCode: securityCode,
                                securityName: row.original.companyName,
                            }}
                        />
                    )
                },
            },
            {
                accessorKey: 'orderSide',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Type</span>
                },
                cell: ({ row }) => {
                    return <span className="capitalize">{row.original.orderSide.toLowerCase()}</span>
                },
            },
            {
                accessorKey: 'units',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Quantity</span>
                },
            },
            {
                accessorKey: 'status',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Status</span>
                },
            },
            {
                accessorKey: 'trade',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold"></span>
                },
                cell: ({ row }) => {
                    return (
                        <div className="flex gap-2">
                            <Button
                                variant="tertiary"
                                size="sm"
                                onClick={() => {
                                    setCurrentOrder(row.original)
                                    setReviewOrderModalOpen(true)
                                }}
                            >
                                {[orderStatus['IN MARKET'], orderStatus.REQUESTED].includes(row.original.status)
                                    ? 'Review'
                                    : 'View'}
                            </Button>
                        </div>
                    )
                },
            },
        ],
        [setCurrentOrder],
    )

    return (
        <>
            <DataTable columns={columns} data={orders} noDataMessage="No orders yet..." />
            <ReviewOrderModal
                open={reviewOrderModalOpen}
                onClose={() => setReviewOrderModalOpen(false)}
                onCancelOrder={() => {
                    setReviewOrderModalOpen(false)
                    setCancelOrderSuccessModalOpen(true)
                }}
            />
            <CancelOrderSuccessModal
                open={cancelOrderSuccessModalOpen}
                onClose={() => {
                    setCancelOrderSuccessModalOpen(false)
                    setCurrentOrder(null)
                }}
            />
        </>
    )
}
