import { H1, H2 } from '@/common/ui'
import { SecurityCard } from '@/common/ui/SecurityCard'
import { cn } from '@/common/utils/utils'
import { useFetchEquityPrices, useFetchHistoricalPrice, useFetchTopTradedSecurities } from '@/trading/tradingQueries'
import { Link } from 'react-router-dom'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/common/ui/accordion'
import { useEffect } from 'react'
import { analytics } from '@/common/analytics/analytics.ts'
import { Info } from 'lucide-react'

const data = {
    title: 'What shares should I buy first?',
    author: {
        name: 'Adam Verwey',
        role: 'CEO and founder of SIX',
        date: 'October 15, 2024',
        image: '/adam-avatar.jpeg',
    },
    sections: [
        {
            title: 'Starting with between $500 to $2,000',
            content:
                'If you’re starting your share investing journey with around $500 up to $2,000, then a good place to start looking at might be Exchange Traded Funds, or ETFs.\nAs the minimum amount you can initially invest in a company on the ASX is $500, if you were to choose to invest in one or two companies, then you’re putting all your eggs in those one or two baskets.\nWith an ETF, however, you can buy into a whole portfolio of companies. Investing in an ETF is a convenient way to invest in many different companies in just one trade. It can be a less risky way to invest than picking individual companies as your money is spread across many companies.\nThere’s a wide range of ETFs available on the ASX, and they usually have a particular theme. It might be a broad theme like “Australian shares” that invests in all the largest companies on the ASX, or a more specific theme like AI or renewable energy or food.\nAnother good thing about ETFs is that they are transparent. You can access information about how they select the companies for the portfolio, and provide a daily list of all the companies that the ETF is invested in. This is a great way to check that an ETF matches your values.\nThe following are the largest Sustainability ETFs on the ASX:',
            securities: [
                {
                    securityCode: 'ETHI',
                    name: 'Betashares Global Sustainability Leaders',
                },
                {
                    securityCode: 'FAIR',
                    name: 'Betashares Australian Sustainability Leaders',
                },
                {
                    securityCode: 'VESG',
                    name: 'Vanguard Ethically Conscious International Shares',
                },
                {
                    securityCode: 'IWLD',
                    name: 'iShares Core MSCI World ex Australia ESG ETF',
                },
                {
                    securityCode: 'VETH',
                    name: 'Vanguard Ethically Conscious Australian Shares',
                },
                {
                    securityCode: 'RARI',
                    name: 'Russell Australian Responsible Investment',
                },
                {
                    securityCode: 'IESG',
                    name: 'iShares Core MSCI Australia ESG Leaders ETF',
                },
                {
                    securityCode: 'ESGI',
                    name: 'Van Eck Vectors MSCI International Sustainable Equity',
                },
                {
                    securityCode: 'GRNV',
                    name: 'Van Eck MSCI Australian Sustainable Equity',
                },
            ],
        },
        {
            title: 'Starting with between $2,000 to $10,000',
            content:
                'ETFs aren’t just good for your first investment, they can also be a good diversified base for a larger share portfolio.\nWith a larger amount of money to invest, you have the opportunity to diversify and hold different types of ETFs. If you want to use ETFs to invest in both Australian and overseas companies, then you can buy an ETF with Australian shares and one with International shares in it. Or, if there’s a theme that you’re really into, like electric vehicles or video games or food - there’s a good chance there’s an ETF that invests in companies in that theme.\nWith a bit more money, you might also want to buy shares directly into a company. You could buy shares to support one of SIX’s activist campaigns, or else pick a company that you like.\nCompanies on the ASX provide a lot of reporting about their activities and financials, and if you’ve got the time then you should have a read before investing. However, here’s some helpful questions to ask yourself before making an investment in a company:\nHave I heard about this company before?\nDo I understand, and like, what this company does as its main business?\nDo I think this company offers a product or service that people will still want to use in the future?\nIf you like what a company does, and think what it does is important, then this can make the emotional side of investing a bit easier.',
            securities: [
                {
                    securityCode: 'ERTH',
                    name: 'Betashares Climate Change Solutions ETF',
                },
                {
                    securityCode: 'CLNE',
                    name: 'VanEck Global Clean Energy ETF',
                },
                {
                    securityCode: 'ACDC',
                    name: 'Global X Battery Tech & Lithium ETF',
                },
                {
                    securityCode: 'IEAT',
                    name: 'Betashares Future of Food ETF',
                },
                {
                    securityCode: 'DRIV',
                    name: 'BetaShares Electric Vehicles and Future Mobility ETF',
                },
            ],
        },
        {
            title: 'Starting with $10,000 or more',
            content:
                'With a bit more money, you can invest in more things. But the basics of investing stay the same no matter how much money you have to invest. So getting started with more than $10k to invest looks pretty similar to getting started with less than $10k to invest.\nETFs can be a great base for a portfolio as they can give diversification across many companies, many countries and many different sectors. With some additional funds, you may like to add shares in individual companies that you like, or support 1 or more of our shareholder activism campaigns.\nIf you want to see what companies other SIX users are buying, then these are the top 6 most traded companies on SIX:',
        },
    ],
}

function Security({ security, section }: { security: any; section: string }) {
    const { data: equityPrice } = useFetchEquityPrices(security.securityCode)
    const { data: historicalPrices } = useFetchHistoricalPrice(security.securityCode, 'YTD')
    const price = equityPrice?.buyPrice ?? equityPrice?.closingPrice
    if (!price || !historicalPrices || historicalPrices.length === 0) return

    const changeInPriceYTD = ((price - historicalPrices[0]?.close) / historicalPrices[0]?.close) * 100
    const roundedChangeInPriceYTD = Math.round(changeInPriceYTD * 100) / 100

    return (
        <div className="justify-start items-start inline-flex w-full">
            <Link
                className="w-full"
                to={`/equity/${security.securityCode}`}
                onClick={() =>
                    analytics.track('GettingStartedSecurityCard Clicked', {
                        section: section,
                        securityCode: security.securityDetails.securityCode,
                        price:
                            security.securityDetails.priceInfo.buyPrice ??
                            security.securityDetails.priceInfo.closingPrice,
                        changeInPriceYTD: security.changeInPriceYTD,
                        // @ts-ignore
                        numPositiveTags: etf.ethicalTags.reduce((acc, curr) => (curr.isTagPositive ? acc + 1 : 0), 0),
                        // @ts-ignore
                        numNegativeTags: etf.ethicalTags.reduce((acc, curr) => (curr.isTagPositive ? 0 : 1), 0),
                    })
                }
            >
                <SecurityCard
                    securityCode={security.securityCode}
                    securityName={security.name}
                    price={price}
                    withEthicalTags={false}
                    changeInPriceYTD={roundedChangeInPriceYTD}
                    historicalPrices={historicalPrices}
                />
            </Link>
        </div>
    )
}

export const GettingStarted = () => {
    const { data: topTradedSecurities } = useFetchTopTradedSecurities()

    useEffect(() => {
        const element = document.getElementById('header')
        element && element.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <div className="flex flex-col justify-start items-start">
            <div id="header" className="flex flex-col justify-start items-start">
                <H1 className="mb-6">{data.title}</H1>
                <div className="justify-start items-start gap-4 inline-flex">
                    <img
                        className="object-cover w-16 h-16 rounded-full shrink-0 overflow-hidden"
                        src={data.author.image}
                        alt={data.author.name}
                    />
                    <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                        <div className="text-black text-base font-medium leading-tight">
                            {data.author.name}, {data.author.role}
                        </div>
                        <div className="text-stone-700 text-base font-medium leading-tight">{data.author.date}</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row-reverse mt-12">
                <div className="md:block w-full flex-col justify-start items-start gap-6 inline-flex">
                    <div className="md:sticky top-0">
                        <div className="text-black text-2xl font-normal leading-[28.80px]">On this page</div>
                        <div className="self-stretch rounded-[10px] flex-col justify-start items-start flex mt-6 bg-[#F9F9F9]">
                            {data.sections.map((section, index) => (
                                <button
                                    key={index}
                                    className="self-stretch text-left w-full hover:bg-[#ecefe9] rounded-[10px] hover:font-semibold pl-9 pr-5 py-5 justify-start items-start gap-2.5 inline-flex cursor-pointer"
                                    onClick={() => {
                                        const element = document.getElementById(`section-${index}`)
                                        analytics.track(`GettingStarted ${element?.title} clicked`)
                                        if (element) {
                                            element.scrollIntoView({ behavior: 'smooth' })
                                        }
                                    }}
                                >
                                    <div className="grow shrink basis-0 text-black leading-snug">{section.title}</div>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="justify-start items-start gap-12 flex flex-col">
                    <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                        <div className="self-stretch justify-start items-center inline-flex">
                            <h2 className="text-3xl md:text-4xl mb-4 font-medium">Summary</h2>
                        </div>
                        <div className="self-stretch pr-6 rounded-[10px] justify-center items-center gap-10 inline-flex">
                            <div className="self-stretch text-black leading-7 mb-4 border-l-8 border-[#d5d8d3] font-light pl-6 pt-2">
                                <p className="mb-3">
                                    I’ve just opened up a trading account on SIX. But what shares should I buy? Follow
                                    these tips from Adam to get started no matter what amount of money you’re starting
                                    off with.
                                </p>
                            </div>
                        </div>
                        <div className="p-6 flex gap-6 rounded-[20px] border border-gray-300 bg-white mr-6">
                            <Info size={24} strokeWidth={2} className="flex-shrink-0" />
                            <div>
                                Before we jump in, a quick reminder that any views, recommendations or opinions shared
                                here are general in nature only and do not take into account any specific person’s
                                circumstances, objectives or goals. It’s really important to do your own research before
                                making any investment decisions. And remember, all investing carries risk!
                            </div>
                        </div>
                    </div>
                    <div className="md:hidden bg-[#F9F9F9] sticky w-full top-0">
                        <Accordion type="single" collapsible className="w-full">
                            <AccordionItem value="item-1">
                                <AccordionTrigger className="!no-underline px-6">
                                    <div className="text-black text-2xl font-normal leading-[28.80px]">
                                        On this page
                                    </div>
                                </AccordionTrigger>
                                <AccordionContent>
                                    {data.sections.map((section, index) => (
                                        <button
                                            key={index}
                                            className="self-stretch text-left hover:bg-[#ecefe9] rounded-[10px] hover:font-semibold pl-9 pr-5 py-5 justify-start items-start gap-2.5 inline-flex cursor-pointer"
                                            onClick={() => {
                                                const element = document.getElementById(`section-${index}`)
                                                analytics.track(`GettingStarted ${element?.title} clicked`)
                                                if (element) {
                                                    element.scrollIntoView({ behavior: 'smooth' })
                                                }
                                            }}
                                        >
                                            <div className="grow shrink basis-0 text-black leading-snug">
                                                {section.title}
                                            </div>
                                        </button>
                                    ))}
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex pr-4">
                        {data.sections.map((section, index) => (
                            <div
                                id={`section-${index}`}
                                key={index}
                                className="self-stretch flex-col justify-start items-start flex"
                            >
                                <div className="self-stretch justify-start items-center inline-flex">
                                    <H2>{section.title}</H2>
                                </div>
                                <div className="self-stretch pr-6 rounded-[10px] justify-center items-center gap-10 inline-flex">
                                    <div className={cn('self-stretch text-black leading-7 mb-4')}>
                                        {section.content.split('\n').map((line, i) => (
                                            <p className="mb-3" key={i}>
                                                {line}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                {section.securities && (
                                    <div className="justify-between items-start flex flex-col md:flex-row md:flex-wrap w-full">
                                        {section.securities.map((security, index) => (
                                            <div className="w-full md:w-[48.5%] mb-6">
                                                <Security key={index} security={security} section={section.title} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                        {topTradedSecurities && (
                            <div className="justify-between items-start flex flex-col md:flex-row md:flex-wrap w-full">
                                {topTradedSecurities.map((security: any, index: number) => (
                                    <div className="w-full md:w-[48.5%] mb-6">
                                        <Security key={index} security={security} section={'Top traded securities'} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
