import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { AddressDetails, PriceDataPoint, User } from '../types'
import { MIN_TRADE_FEE, MONTHS, TRADE_AMOUNT_FOR_PERCENTAGE, TRADE_FEE_PERCENTAGE } from '../constants'
import Money from '../models/money'

export function seconds(time: number) {
    return time * 1000
}

export function canNavigateToDashboard(user: { addresses: object | null; firstName: string } | undefined) {
    return user && user?.firstName && user?.addresses
}

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function getFullName(user: User) {
    const firstName = user.firstName ? `${user.firstName} ` : ''
    const lastName = user.lastName ? user.lastName : ''
    const salutation = user.salutation ? `${user.salutation} ` : ''
    const middleName = user.middleName ? `${user.middleName} ` : ''
    return `${salutation}${firstName}${middleName}${lastName}`
}

export function getAddressString(address: AddressDetails) {
    if (!address) return ''
    const buildingNumber =
        address.addressPrefix && address.addressPrefix.trim().length ? `${address.addressPrefix}, ` : ''
    const streetType = address.streetType ? ` ${address.streetType}` : ''
    return `${buildingNumber}${address.streetNumber} ${address.streetName}${streetType}, ${address.city}, ${address.state} ${address.postCode}`
}

export function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
): (...args: Params) => void {
    let timerId: ReturnType<typeof setTimeout>
    return (...args: Params) => {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func(...args)
        }, timeout)
    }
}

export const insertStringVariables = (text: string, ...args: string[]) => {
    let str = text
    for (const arg of args) {
        str = str.replace('{}', arg)
    }
    return str
}

export const inMillionsOrBillions = (num: number) => {
    const inMillion = num / 1000000
    return inMillion > 999 ? `${(inMillion / 1000).toFixed(2)}B` : `${inMillion.toFixed(2)}M`
}

export const getPriceRange = (pricePoints: PriceDataPoint[]): [number, number] => {
    return pricePoints?.reduce(
        (acc, price) => {
            if (price.close < acc[0]) {
                acc[0] = price.close
            }
            if (price.close > acc[1]) {
                acc[1] = price.close
            }
            return acc
        },
        [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
    )
}

export const tradeEstimatedBrokerage = (amount: Money = Money.ZERO) => {
    return amount.lessThan(TRADE_AMOUNT_FOR_PERCENTAGE)
        ? MIN_TRADE_FEE
        : amount.multiply(TRADE_FEE_PERCENTAGE).divide(100)
}

export const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.getDate() + ' ' + MONTHS[date.getMonth()] + ' ' + date.getFullYear()
}

export const formatCurrency = (amount: number) => {
    return amount.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
}

export const isValidPriceFormat = (price: number, instrumentPrice: number) => {
    // If instrument price is less than $2, then allow 3 decimals
    const regex = Money.of(instrumentPrice).lessThan(Money.of(2)) ? /^\d*\.?\d{0,3}$/ : /^\d*\.?\d{0,2}$/
    return regex.test(price.toString())
}

export const getStepSize = (instrumentPrice: number) => {
    if (!instrumentPrice || (instrumentPrice >= 0.001 && instrumentPrice < 0.01)) return 0.001

    if (instrumentPrice >= 0.1 && instrumentPrice < 2) return 0.005

    return 0.01
}

export const doesFollowValidStepSize = (price: number, instrumentPrice: number) => {
    return Math.round(price * 1000) % Math.round(getStepSize(instrumentPrice) * 1000) === 0
}

export const isLimitPriceValid = (price: number, instrumentPrice: number): boolean => {
    const validPercent = 5
    const diff = Math.abs(instrumentPrice - price)
    const percentageDiff = (diff / instrumentPrice) * 100
    return (
        isValidPriceFormat(price, instrumentPrice) &&
        doesFollowValidStepSize(price, instrumentPrice) &&
        percentageDiff <= validPercent
    )
}

export const isSizeAboveMin = (sharesCurrentlyHeld: number | undefined, price: number, shares: number) => {
    return sharesCurrentlyHeld && sharesCurrentlyHeld > 0 ? price : price * shares >= 500
}

export const isAvailableBalanceSufficient = (balance: Money, estimatedCostOfShares: Money) =>
    !balance.lessThan(estimatedCostOfShares)
