import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useFetchEquityDetails, useFetchHistoricalPrice } from '@/trading/tradingQueries'
import { getPriceRange } from '@/common/utils/utils'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP } from '@/common/strings'
import { EquityDetailsPageHeader } from './EquityDetailsPageHeader'
import { EquityPriceAndGraph } from './EquityPriceAndGraph'
import { H3 } from '@/common/ui'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { BuildingIcon, CoinsIcon, SproutIcon } from 'lucide-react'
import { useEsgProfile } from '@/research/researchQueries.ts'
import { CompanyProfile } from './companyProfile'
import { FinancialProfile } from './financialProfile'
import { EthicalProfile } from './EthicalProfile.tsx'
import { analytics } from '@/common/analytics/analytics'

function Tabs({
    onSelectionChange,
    selectedTab,
    tabLabels,
    securityCode,
}: {
    onSelectionChange: (label: any) => void
    selectedTab: any
    tabLabels: any
    securityCode: string
}) {
    return (
        <div className="flex rounded-full w-full border-neutral-200 mb-4 border flex-row p-1">
            {tabLabels.map((label: any, index: number) => (
                <button className={`${2 === index ? '' : 'border-r '} w-full p-1`}>
                    <div
                        className={`w-full p-2.5 ${
                            selectedTab?.text === label.text ? 'bg-[#ECEFE9]' : 'hover:bg-stone-100'
                        }  rounded-[100px] justify-center items-center gap-1 flex`}
                        onClick={() => {
                            onSelectionChange(label)
                            analytics.track(`${label.text.replace(' ', '')} Viewed`, { securityCode })
                        }}
                    >
                        {label.icon} {label.text}
                    </div>
                </button>
            ))}
        </div>
    )
}

const tabLabels = [
    {
        text: 'Ethical Profile',
        icon: <SproutIcon />,
    },
    {
        text: 'Financial Profile',
        icon: <CoinsIcon />,
    },
    {
        text: 'Company Profile',
        icon: <BuildingIcon />,
    },
]

export const EquityDetails = () => {
    const { securityCode } = useParams()
    const { showCompanyProfilePages } = useFeatureFlags()

    const { data: equity } = useFetchEquityDetails(securityCode)
    const { data: profile } = useEsgProfile(securityCode)
    const { data: yearPricePoints } = useFetchHistoricalPrice(equity?.securityCode, '1Y')

    const [selectedTab, setSelectedTab] = useState(tabLabels[0])
    const yearRange = useMemo(
        () => (yearPricePoints && yearPricePoints.length > 0 ? getPriceRange(yearPricePoints) : null),
        [yearPricePoints],
    )

    useEffect(() => {
        analytics.track(`${selectedTab.text} tab clicked`)
    }, [selectedTab, setSelectedTab])

    if (!securityCode || !equity) return <FullScreenLoader className="h-20" />

    if (!showCompanyProfilePages) return null

    return (
        <>
            <Helmet>
                <title>
                    {securityCode} | {APP.title}
                </title>
            </Helmet>
            <div className="sticky top-0 bg-white">
                <EquityDetailsPageHeader securityName={equity.description} />
            </div>
            <main className="flex flex-col gap-8">
                <div className="grid grid-cols-1 xl:grid-cols-[2fr_1fr] gap-8">
                    <EquityPriceAndGraph securityCode={securityCode} />
                </div>

                <Tabs
                    tabLabels={tabLabels}
                    onSelectionChange={setSelectedTab}
                    selectedTab={selectedTab}
                    securityCode={securityCode}
                />
                {selectedTab.text === 'Ethical Profile' && <EthicalProfile data={profile?.data} />}
                {selectedTab.text === 'Financial Profile' && (
                    <FinancialProfile yearRange={yearRange} statistics={equity.statistics} />
                )}
                {selectedTab.text === 'Company Profile' && (
                    <>
                        <H3>Company snapshot</H3>
                        <p className="mb-4">{equity.about.description}</p>
                        <p>{equity?.about.history}</p>
                        <CompanyProfile about={equity.about} />
                    </>
                )}
            </main>
        </>
    )
}
