import { Check } from 'lucide-react'

import { Button, H2, H3 } from '@/common/ui'
import { InstrumentT } from '@/common/types'
import { FC } from 'react'
import { ReviewItems } from './revewItems'
import useTradeStore from './tradeStore'

type Props = {
    instrument: InstrumentT
    onClose: () => void
}
export const OrderConfirmation: FC<Props> = ({ instrument, onClose }) => {
    const { orderAction } = useTradeStore()

    return (
        <div>
            <span className="w-14 h-14 bg-secondary rounded-full mx-auto flex justify-center items-center">
                <Check />
            </span>
            <H2 className="text-center mt-2 md:text-lg">Thanks, we have received your order</H2>
            <H3 className="text-center">Order Summary</H3>
            <ReviewItems instrument={instrument} />
            {orderAction === 'BUY' && (
                <p className="text-xs mt-4 text-center text-balance">
                    The last step to ensure your shares are supporting the campaign is to sign SIX's agency agreement
                    (available soon in the app under 'Campaigns')
                </p>
            )}
            <div>
                <Button onClick={onClose} className="mx-auto block mt-8" size="lg" variant="secondary">
                    Close
                </Button>
            </div>
        </div>
    )
}
