import { analytics } from '@/common/analytics/analytics'
import { SecurityCard } from '@/common/ui/SecurityCard'
import { useFetchRecommendedSecurities } from '@/trading/tradingQueries'
import { Link } from 'react-router-dom'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/common/ui/carousel.tsx'

function securityCardWithLink(security: any) {
    return (
        <Link
            key={security.securityDetails.securityCode}
            to={`/equity/${security.securityDetails.securityCode}`}
            onClick={() =>
                analytics.track('EthicalETFSecurityCard Clicked', {
                    securityCode: security.securityDetails.securityCode,
                    price:
                        security.securityDetails.priceInfo.buyPrice ?? security.securityDetails.priceInfo.closingPrice,
                    changeInPriceYTD: security.changeInPriceYTD,
                    numPositiveTags: security.ethicalTags.reduce(
                        (acc: number, curr: any) => (curr.isTagPositive ? acc + 1 : acc),
                        0,
                    ),
                    numNegativeTags: security.ethicalTags.reduce(
                        (acc: number, curr: any) => (curr.isTagPositive ? acc : acc + 1),
                        0,
                    ),
                })
            }
        >
            <SecurityCard
                securityCode={security.securityDetails.securityCode}
                securityName={security.securityName ?? security.securityDetails.securityName}
                price={security.securityDetails.priceInfo.buyPrice ?? security.securityDetails.priceInfo.closingPrice}
                changeInPriceYTD={security.changeInPriceYTD}
                historicalPrices={security.historicalPrices}
                ethicalTags={security.ethicalTags}
            />
        </Link>
    )
}

function RecommendedETFsView({ recommendedETFs }: { recommendedETFs: any }) {
    if (!recommendedETFs) {
        return null
    }

    return (
        <div className="flex flex-col mt-10 justify-start w-full pb-6 items-start gap-4">
            <div className="self-stretch flex justify-start items-start gap-8">
                <div className="grow shrink basis-0 h-[22px] flex justify-start items-center gap-8">
                    <div className="text-center text-black text-xl font-['Serious Sans'] leading-snug">
                        Trending ethical ETFs
                    </div>
                </div>
            </div>
            <div className="text-[#6B7280] mb-3 text-sm">
                The companies listed above were selected by SIX based on being the largest sustainability
                Exchange-Traded Funds (ETFs) on the Australian Stock Exchange (ASX) and are not a recommendation to buy
                or sell these shares. Please ensure you conduct your own research before making any investment
                decisions.
            </div>

            <div className="hidden lg:grid mt-5 grid-wrap grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                {recommendedETFs?.map((security: any) => securityCardWithLink(security))}
            </div>

            <div className="lg:hidden flex justify-center w-full">
                <Carousel className="w-full flex max-w-sm">
                    <CarouselContent className="-ml-1">
                        {recommendedETFs?.map((security: any) => (
                            <CarouselItem className="pl-1" key={security.securityDetails.securityCode}>
                                <div className="p-1">{securityCardWithLink(security)}</div>
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                    <CarouselPrevious />
                    <CarouselNext />
                </Carousel>
            </div>
        </div>
    )
}

export function RecommendedETFs() {
    const { data: recommendedETFs } = useFetchRecommendedSecurities()

    return <RecommendedETFsView recommendedETFs={recommendedETFs} />
}
